<template>
  <div v-editable="blok" class="constrain py-6 lg:py-24 relative z-10">
    <h3 class="heading-2 text-center">{{ blok.title }}</h3>

    <!-- Region Selector with Horizontal Scrolling -->
    <div class="flex flex-nowrap md:flex-wrap md:justify-center overflow-x-auto w-full md:max-w-[1000px] mx-auto gap-4 pt-4 lg:pt-16">
      <div
        v-for="group in blok.locationGroups" :key="group.name"
        @click="setActiveGroup(group.name)"
        :class="[
          'whitespace-nowrap rounded-md py-2 px-6 text-lg font-semibold text-center cursor-pointer',
          'hover:bg-[#F8F4FF] hover:text-primary',
          group.name === activeGroup ? 'bg-[#F8F4FF] text-primary' : 'text-grey-5'
        ]"
      >
        {{ group.name }}
      </div>
    </div>

    <!-- Location List for Active Region -->
    <div class="pt-4 lg:mt-16 grid grid-cols-2 lg:grid-cols-5 gap-4 lg:gap-12">
      <template v-for="locationGroup in blok.locationGroups">
        <LocationCard
          v-if="locationGroup.name === activeGroup"
          v-for="location in locationGroup.locations"
          :location="location"
          :loctaionGroup="locationGroup"
          :key="location"
        />
      </template>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({ blok: Object });

import LocationCard from "~/components/locations/LocationCard.vue";

const activeGroup = ref("Popular");

const setActiveGroup = (groupName) => {
  activeGroup.value = groupName;
};

onMounted(() => {
  setActiveGroup(props.blok.locationGroups[0].name);
});
</script>
